import React from 'react';
import "../../Style.css";

import { FormattedMessage } from 'react-intl';

class PietShort extends React.Component {
    render() {
        return (
            <p><FormattedMessage id="piet.paragraph1"></FormattedMessage></p>
            )
    }
}

export default PietShort;