import React from 'reactn';
import { setGlobal, withGlobal } from "reactn";
import './App.css';


// Für das Routen
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

// Für I18n (Internationalisation)
import { addLocaleData } from "react-intl";
import { IntlProvider } from "react-intl";
import locale_en from "react-intl/locale-data/en";
import locale_de from "react-intl/locale-data/de";
import messages_de from "./translation/de.json";
import messages_en from "./translation/en.json";

// import von Layouts
import Navbar from "./components/layouts/Navbar/Navbar.js";
import Footer from "./components/layouts/Footer/Footer.js";

// import von Pages
import AboutUs from './components/pages/Home/AboutUs.js';
import Impressum from './components/pages/Impressum/Impressum.js';
import Themenschwerpunkte from "./components/pages/Themenschwerpunkte/Themenschwerpunkte.js";
import Kompetenzen from "./components/pages/Kompetenzen/Kompetenzen.js";
import Information from "./components/pages/Information/Information.js";
import Kontakt from "./components/pages/Kontakt/Kontakt.js";
import Projekte from "./components/pages/Projekte/Projekte.js";

const messages = {
  "de": messages_de,
  "en": messages_en
}


class App extends React.Component {
  constructor(props) {
    super(props);
    addLocaleData([...locale_en, ...locale_de]);

    setGlobal({
      language: (localStorage.getItem("currentLanguage") === "en") ? "en" : "de"
    });

  }


  render() {
    const { language } = this.props;
    return (
      <IntlProvider locale={language} messages={messages[language]}>
        <Router >
          <div>
            <Navbar />
            <Switch>
              <Route exact path="/aboutUs" component={AboutUs} />
              <Route path="/projects" component={Projekte} />
              <Route path="/mainFields" component={Themenschwerpunkte} />
              <Route path="/competences" component={Kompetenzen} />
              <Route path="/information" component={Information} />
              <Route path="/contact" component={Kontakt} />
              <Route path="/impressum" component={Impressum} />
              <Route component={AboutUs} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </IntlProvider>
    );
  }


}

// setzt die Props auf globale Status. Dadurch kann man auf den Globalen Status Language zugreifen.
export default withGlobal(
  global => ({
    language: global.language
  })
)(App);
