import React, { withGlobal } from 'reactn';

import { FormattedMessage } from 'react-intl';

// Bilder importieren
import imgTitlemobile from "../../assets/images/imgtitlemobile.svg";
import imgTitlemobileEng from "../../assets/images/imgtitlemobileEn.svg";
import imgTitle from "../../assets/images/imgtitle.svg";
import imgTitleEng from "../../assets/images/imgtitleEn.svg";
import img1 from "../../assets/images/home/img1.jpg";
import img2 from "../../assets/images/home/img2.jpg";
import img3 from "../../assets/images/home/img3.jpg";
import img4 from "../../assets/images/home/img4.jpg";
import img5 from "../../assets/images/home/img5.jpg";



class AboutUs extends React.Component {

    componentDidMount() {
        window._paq.push(['setCustomUrl', "/aboutUS"]);
        window._paq.push(['setDocumentTitle', "Home"]);
        window._paq.push(['trackPageView']);
    }

    render() {
        const { language } = this.props;
        document.title = "AboutUs";
        
        return (
            // Container
            <div className="content-main container  col-md-8 col-md-offset-2 ">
                {/* Erste Zeile mit dem Eyecatcher */}

                <div className="row">
                    <div className="col-sm-12 hidden-xs">
                        {/* ../../assets/images/imgtitle.svg */}
                        <object data={(language === "de") ? imgTitle : imgTitleEng} type="image/svg+xml" >Wir entwickeln individuelle Softwarelösungen</object>
                    </div>
                    <div className="col-xs-12 visible-xs">
                        {/* ../img/imgtitlemobile.svg */}
                        <img src={(language === "de") ? imgTitlemobile : imgTitlemobileEng} style={{ width: "100%" }} alt="Bild" />
                    </div>
                </div>

                {/* Zweite Zeile mit dem "Über uns" Text */}

                <div className="row">

                    <div className="col-md-8 rwText">
                        <h3><FormattedMessage id="überuns.headline1">Über uns</FormattedMessage></h3>
                        <p>
                            <FormattedMessage id="überuns.text1">
                                Seit über zehn Jahren befassen wir uns mit der Konzeption, Durchführung und Betreuung von Softwareprojekten für Projektdienstleister und Behörden. Zentrale Bestandteile unserer Arbeit sind Beratung und Analyse, auf deren Grundlage wir Entwürfe für benötigte
                                Systemarchitekturen entwickeln, die letztendlich in passgenaue Softwarelösungen umgesetzt werden. Unsere IT-Lösungen umfassen Datenbanken, Webanwendungen, Business Intelligence-Systeme und vieles mehr. Unser Ziel ist es, problemorientiert unter
                                Kosten-/Nutzenaspekten optimale Lösungen in Kooperation mit unseren Kunden zu erarbeiten.
                            </FormattedMessage>
                        </p>
                    </div>

                    {/* Graue Box seitlich der zweiten Zeile  */}


                    <div className="col-md-3 col-xs-12 sma">
                        <ul className="liste col-xs-9 col-md-12">
                            <p><b><FormattedMessage id="überuns.unsereKunden"> Unsere Kunden </FormattedMessage> </b> </p>
                            <p />
                            <li><FormattedMessage id="überuns.kunde1">agiplan GmbH</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde2">CPB Software (Austria) GmbH</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde3">Effizienz-Agentur NRW</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde4">Euregio Maas-Rhein/Provincie Limburg</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde5">Europäische Kommission DG-Regio</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde6">Interact</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde7">KlimaExpo.NRW</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde8">Ministerium der Deutschsprachigen Gemeinschaft Belgiens</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde9">Ministerium der Finanzen des Landes Sachsen-Anhalt</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde10">Ministerium für Innovation, Wissenschaft und Forschung des Landes Nordrhein-Westfalen</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde11">Ministerium für Wirtschaft, Bau und Tourismus des Landes Mecklenburg-Vorpommern</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde12">Ministerium für Wirtschaft, Energie, Industrie, Mittelstand und Handwerk des Landes Nordrhein-Westfalen</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde13">PPMI Group, UAB</FormattedMessage></li>
                            <p />
                            <li><FormattedMessage id="überuns.kunde14">Prognos AG</FormattedMessage></li>
                        </ul>
                    </div>
                </div>

                {/* Dritte Zeile mit den Bilder */}

                <div className="row">
                    <div className="col-md-4 col-xs-6">
                        {/* ../img/img1.jpg */}
                        <img src={img1} style={{ width: "100%" }} alt="Bild" />
                    </div>
                    <div className="col-md-4 col-xs-6">
                        {/* ../img/img3.jpg */}
                        <img src={img3} style={{ width: "100%" }} alt="Bild" />
                    </div>
                    <div className="col-md-4 col-xs-12">
                        {/* ../img/img5.jpg */}
                        <img src={img5} style={{ width: "100%" }} alt="Bild" />
                    </div>
                </div>

                {/* Bilder der Mobilen Version */}

                <div className="row">
                    <div className="col-xs-6 visible-xs">
                        {/* ../img/img2.jpg */}
                        <img src={img2} style={{ width: "100%" }} alt="Bild" />
                    </div>
                    <div className="col-xs-6 visible-xs">
                        {/* ../img/img4.jpg */}
                        <img src={img4} style={{ width: "100%" }} alt="Bild" />
                    </div>
                </div>

                {/* Vierte Zeile mit dem Wir setztn auf Dialoge Text */}

                <div className="row">
                    <div className="col-md-9 rwText">
                        <h3><FormattedMessage id="überuns.headline2">Wir setzen auf Dialog und beraten Sie umfassend.</FormattedMessage></h3>

                        <p>
                            <FormattedMessage id="überuns.text2">
                                Potentielle Schwachstellen identifizieren,
                                gemeinsam gute Lösungen finden: Dafür bieten wir Workshops an,
                                entwickeln Studien und konzipieren Leistungsspezifikationen.
                            </FormattedMessage>
                        </p>
                    </div>
                </div>

            </div>
        )
    }
}

// setzt die Props auf globale Status. Dadurch kann man auf den Globalen Status Language zugreifen.
export default withGlobal(
    global => ({
        language: global.language
    })
)(AboutUs);