import React from 'react';

import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import logo from "../../assets/images/logo.svg";



class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state= { menuActive: false
                    };

        this.closeMenu = this.closeMenu.bind(this);
        this.openMenu = this.openMenu.bind(this);
    }

    closeMenu() {
        this.setState({menuActive: false
                    });
    }

    openMenu() {
        this.setState({menuActive: true
        });
    }

    render() {
        return (
            <nav className="navbar navbar-default  navbar-fixed-top">

                {/* Rechenwerk Logo */}
                <NavLink className="navbar-brand pull-right logonavi hidden-xs hidden-sm " to="aboutUs"><img src={logo} alt="Logo" /></NavLink>

                {/* Burgermenü öffenen */}
                <span id="menuOpen">

                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" onClick={this.openMenu}>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>

                </span>

                <div className="container" id="navbar">

                    <div className="collapse navbar-collapse hidden-xs  hidden-sm " id="myNavbar">

                        <ul className="nav navbar-nav navbar-center hidden-sm">
                            <li ><NavLink exact to="/aboutUs"><FormattedMessage id="navbar.überuns">Über uns</FormattedMessage></NavLink></li>
                            <li ><NavLink exact to="/projects"><FormattedMessage id="navbar.projekte">Projekte</FormattedMessage></NavLink></li>
                            <li ><NavLink exact to="/mainFields"><FormattedMessage id="navbar.themenschwerpunkte">Themenschwerpunkte</FormattedMessage></NavLink></li>
                            <li ><NavLink exact to="/competences"><FormattedMessage id="navbar.kompetenzen">Kompetenzen</FormattedMessage></NavLink></li>
                            <li ><NavLink exact to="/information"><FormattedMessage id="navbar.information">Information</FormattedMessage></NavLink></li>
                            <li ><NavLink exact to="/contact"><FormattedMessage id="navbar.kontakt">Kontakt</FormattedMessage></NavLink></li>
                        </ul>

                    </div>

                    <div id="myNav" className="overlay" style={(this.state.menuActive === true) ? {width: "100%"} : {width: "0%"}}>
                        {/* Burgericon geschlossen */}
                        <span id="menuClose">
                            <div className="closebtn" >

                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar" onClick={this.closeMenu}>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>
                        </span>

                        {/* Menüeinträge Mobil */}

                        <div className="overlay-content">
                            <NavLink exact to="/aboutUs" onClick={this.closeMenu}><FormattedMessage id="navbar.mobile.überuns">Über uns</FormattedMessage></NavLink>
                            <NavLink exact to="/projects" onClick={this.closeMenu}><FormattedMessage id="navbar.mobile.projekte">Projekte</FormattedMessage></NavLink>
                            <NavLink exact to="/mainFields" onClick={this.closeMenu}><FormattedMessage id="navbar.mobile.themenschwerpunkte">Themenschwerpunkte</FormattedMessage></NavLink>
                            <NavLink exact to="/competences" onClick={this.closeMenu}><FormattedMessage id="navbar.mobile.kompetenzen">Kompetenzen</FormattedMessage></NavLink>
                            <NavLink exact to="/information" onClick={this.closeMenu}><FormattedMessage id="navbar.mobile.information">Information</FormattedMessage></NavLink>
                            <NavLink exact to="/contact" onClick={this.closeMenu}><FormattedMessage id="navbar.mobile.kontakt">Kontakt</FormattedMessage></NavLink>
                        </div>

                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar;