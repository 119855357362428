import React from 'react';

import imgKontakt01 from "../../assets/images/kontakt/kontakt1.jpg";
import imgKontakt02 from "../../assets/images/kontakt/kontakt2.jpg";


import { FormattedMessage } from 'react-intl';

class Kontakt extends React.Component {

    componentDidMount() {
        window._paq.push(['setCustomUrl', "/contact"]);
        window._paq.push(['setDocumentTitle', "Contact"]);
        window._paq.push(['trackPageView']);
    }

    render() {
        document.title = "Contact";
        return (
            <div>
                <div id="content-kontakt" className="container-fluid bg-themen2">
                    <div className="row">
                        <div className="col-sm-2 col-sm-offset-2 col-xs-11 rwKontakt">
                            <div className="col-sm-9 col-sm-offset-3 col-xs-12">
                                <h3> <FormattedMessage id="kontakt.kontakt"></FormattedMessage></h3>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-sm-3 col-sm-offset-2 col-xs-11">
                            <div className="col-sm-10 col-sm-offset-2 col-xs-12">
                                <p className="font12">
                                    <b><FormattedMessage id="impressum.rechenwerk"></FormattedMessage></b>
                                    <br /><FormattedMessage id="impressum.marthastraße"></FormattedMessage>
                                    <br /><FormattedMessage id="impressum.anschrift"></FormattedMessage>
                                </p>
                                <p className="font12">
                                    <FormattedMessage id="impressum.gericht"></FormattedMessage>
                                <br /> <FormattedMessage id="impressum.hrb"></FormattedMessage>
                                <br /><FormattedMessage id="impressum.ust"></FormattedMessage>
                                <br /> <FormattedMessage id="impressum.de"></FormattedMessage>
                                <br /> <FormattedMessage id="impressum.geschäftsführer"></FormattedMessage>
                                <br /> Christian Bercker
                            </p >
                            </div >
                        </div >

                        <div className="col-sm-1">
                        </div>

                        <div className="col-sm-2 col-xs-11">
                            <img src={imgKontakt01} style={{ width: "100%" }} alt="Bild" />
                            <p> </p>
                            <p className="font12">
                                <b>  Christian Bercker</b>
                                <br /> <FormattedMessage id="impressum.bercker.qualifikation"></FormattedMessage>
                            <br />Tel: +49 (0) 201-815818-76
                            <br />Fax: +49(0) 201 - 815818 - 77
                            <br /> cb@rechenwerk.com
                        </p >
                        </div >

                        <div className="col-sm-2 col-xs-11">
                            <img src={imgKontakt02} style={{ width: "100%" }} alt="Bild" />
                            <p> </p>

                            <p className="font12">
                                <b>  Julian Fieml </b>
                                <br /><FormattedMessage id="impressum.fieml.qualifikation"></FormattedMessage>
                            <br />Tel: +49 (0) 201-815818-78
                            <br />Fax: +49(0) 201 - 815818 - 77
                            <br /> jf@rechenwerk.com
                        </p >
                        </div >
                    </div >
                </div >

                <div id="content-map" class="container col-sm-9 col-sm-offset-1 ">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-3 col-xs-10 col-xs-offset-1  ">
                            <a href="https://www.google.com/maps/place/L%C3%BCneburger+Str.+101,+45145+Essen/@51.4475262,6.9770583,18.5z/data=!4m13!1m7!3m6!1s0x47b8c2f1c33766f3:0xe1e508580a29b695!2sL%C3%BCneburger+Str.+101,+45145+Essen!3b1!8m2!3d51.4475176!4d6.9778338!3m4!1s0x47b8c2f1c33766f3:0xe1e508580a29b695!8m2!3d51.4475176!4d6.9778338"> Anfahrt </a>
                            <iframe style={{ width: "100%", height: "400px" }} frameborder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=rechenwerk%20gmbh&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=12&amp;iwloc=A&amp;output=embed" title="googleMaps"></iframe>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
}

export default Kontakt;