import React from 'react';
import "../../Style.css";

import { FormattedMessage } from 'react-intl';

class PiaShort extends React.Component {
    render() {
        return (
            <p><FormattedMessage id="pia.paragraph1"></FormattedMessage></p>
        )
    }
}

export default PiaShort;