import React from 'react';
import "../../Style.css";


import { FormattedMessage } from 'react-intl';

class SwingMobile extends React.Component {

    render() {
        return (
            <div>
                <p><FormattedMessage id="swing.paragraph1"></FormattedMessage></p>
                <div id="ContentMobile_5" className="ProjektContentMobil" >
                    <p><FormattedMessage id="swing.paragraph2"></FormattedMessage> </p>
                    <p><FormattedMessage id="swing.paragraph3"></FormattedMessage> </p>
                    <p></p>
                    <h3><FormattedMessage id="swing.headline1"></FormattedMessage></h3>
                    <ul className="listfla col-xs-12">
                        <li><p><FormattedMessage id="swing.secondparagraph1"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="swing.secondparagraph2"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="swing.secondparagraph3"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="swing.secondparagraph4"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="swing.secondparagraph5"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="swing.secondparagraph6"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="swing.secondparagraph7"></FormattedMessage></p></li>
                    </ul>
                    <div className="mehr">
                        <p></p>
                        {/* <h3></h3> */}
                        <ul className="listfla col-md-8 ">
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default SwingMobile;