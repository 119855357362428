import React from 'react';
import "../../Style.css";


import { FormattedMessage } from 'react-intl';

class BoeMobile extends React.Component {

  render() {
    return (
      <div>
        <p><FormattedMessage id="boe.paragraph1"></FormattedMessage></p>
        <div id="ContentMobile_3" className="ProjektContentMobil" >
          <p><FormattedMessage id="boe.paragraph2"></FormattedMessage></p>
          <p><FormattedMessage id="boe.paragraph3"></FormattedMessage></p>
          <p><FormattedMessage id="boe.paragraph4"></FormattedMessage></p>
          <h3><FormattedMessage id="boe.headline1"></FormattedMessage></h3>
          <ul className="listfla col-xs-12">
            <li><p><FormattedMessage id="boe.secondparagraph1"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="boe.secondparagraph2"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="boe.secondparagraph3"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="boe.secondparagraph4"></FormattedMessage></p></li>
          </ul>
          <div className="mehr">
            <p></p>
            {/* <h3></h3> */}
            <ul className="listfla col-md-8 ">
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default BoeMobile;