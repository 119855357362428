import React, { withGlobal } from 'reactn';

import { FormattedMessage } from 'react-intl';

// Bilder importieren
import imgThemenschwerpunkte from "../../assets/images/titlethemenschwerpunkte.svg";
import img6 from "../../assets/images/themenschwerpunkte/img6.jpg";
import img7 from "../../assets/images/themenschwerpunkte/img7.jpg";
import img8 from "../../assets/images/themenschwerpunkte/img8.jpg";
import img9 from "../../assets/images/themenschwerpunkte/img9.jpg";
import imgThemenschwerpunkteMobile from "../../assets/images/Themenmobil.svg";
import img6mobile from "../../assets/images/themenschwerpunkte/img6mobil.jpg";
import img9mobile from "../../assets/images/themenschwerpunkte/img9mobil.jpg";
import img10mobile from "../../assets/images/themenschwerpunkte/img10mobil.jpg";
// Wenn noch ein Englisches svg zur verfügung steht, dann bitte hier einfügen
import imgThemenschwerpunkteEng from "../../assets/images/titlethemenschwerpunkte.svg";
import imgThemenschwerpunkteMobileEng from "../../assets/images/Themenmobil.svg";

class Themenschwerpunkte extends React.Component {

    componentDidMount() {
        window._paq.push(['setCustomUrl', "/mainFields"]);
        window._paq.push(['setDocumentTitle', "Main Fields"]);
        window._paq.push(['trackPageView']);
    }

    render() {
        const { language } = this.props;
        document.title = "Main fields";
        return (
            <div>
                <div id="content-headerthemen" className="container-fluid bg-themen">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2 hidden-xs">
                            <object data={(language === "de") ? imgThemenschwerpunkte : imgThemenschwerpunkteEng} type="image/svg+xml" >Wir entwickeln individuelle Themenschwerpunkte</object>
                            {/* <img class="center-block" src="img/titlethemenschwerpunkte.svg"  style="width:100%" alt="Image">  */}
                        </div>

                        <div className="col-xs-12 visible-xs">
                            <object data={(language === "de") ? imgThemenschwerpunkteMobile : imgThemenschwerpunkteMobileEng} type="image/svg+xml" >Wir entwickeln individuelle Themenschwerpunkte</object>
                        </div>

                    </div>
                </div>

                {/* bis hier hin geht alles (y) */}

                <div className="container col-md-8 col-md-offset-2">
                    <div className="row">
                        <div className="col-md-9 rwText">

                            <h3><FormattedMessage id="themenschwerpunkte.themenschwerpunkte"></FormattedMessage></h3>

                            <p className="rwTextblack"><FormattedMessage id="themenschwerpunkte.fördermittel"></FormattedMessage></p>

                            <p className="rwbold"> <FormattedMessage id="themenschwerpunkte.fördermittelText"> </FormattedMessage> </p>



                            <p className="rwGrey"><FormattedMessage id="themenschwerpunkte.finanzmanagement"></FormattedMessage></p>

                            <p><FormattedMessage id="themenschwerpunkte.finazmanagementText"></FormattedMessage></p>



                            <p className="rwGrey"><FormattedMessage id="themenschwerpunkte.monitoring"></FormattedMessage> </p>

                            <p><FormattedMessage id="themenschwerpunkte.monitoringText"> </FormattedMessage></p>





                            <p className="rwTextblack"><FormattedMessage id="themenschwerpunkte.rechnungswesen"> </FormattedMessage></p>

                            <p className="rwbold"><FormattedMessage id="themenschwerpunkte.rechnungswesenText"> </FormattedMessage> </p>



                            <p className="rwGrey"><FormattedMessage id="themenschwerpunkte.projektcontrolling"></FormattedMessage> </p>

                            <p><FormattedMessage id="themenschwerpuntke.projektcontrollingText"></FormattedMessage> </p>



                            <p className="rwGrey"><FormattedMessage id="themenschwerpunkte.schnittstelle"></FormattedMessage> </p>

                            <p><FormattedMessage id="themenschwerpunkte.schnittstelleText"></FormattedMessage> </p>





                            <p className="rwTextblack"> <FormattedMessage id="themenschwerpunkte.government"> </FormattedMessage></p>

                            <p className="rwbold"><FormattedMessage id="themenschwerpunkte.governmentText"></FormattedMessage> </p>



                            <p className="rwGrey"><FormattedMessage id="themenschwerpunkte.cohesion"></FormattedMessage>  </p>

                            <p><FormattedMessage id="themenschwerpunkte.cohesionText"> </FormattedMessage> </p>



                            <p className="rwGrey"><FormattedMessage id="themenschwerpunkte.oGovernment"></FormattedMessage></p>

                            <p><FormattedMessage id="themenschwerpunkte.oGovernmentText"></FormattedMessage> 
                            </p>
                        </div>
                    </div>
                </div>
                <div id="content-themenschwerpunkte" className="container col-md-10 col-md-offset-1">
                    <div className="row">
                        <div className="col-md-1 col-sm-1  hidden-xs">
                        </div>
                        <div className="col-md-2  col-sm-2  hidden-xs">
                            <img src={img6} style={{ width: "100%" }} alt="Bild" />
                        </div>
                        <div className="col-xs-6  visible-xs">
                            <img src={img6mobile} style={{ width: "100%" }} alt="Bild" />
                        </div>
                        <div className="col-md-3  col-sm-3 col-xs-6">
                            <img src={img7} style={{ width: "100%" }} alt="Bild" />
                        </div>
                        <div className="col-md-3 col-sm-3 col-xs-12">
                            <img src={img8} style={{ width: "100%" }} alt="Bild" />
                        </div>
                        <div className="col-md-2 col-sm-2 hidden-xs">
                            <img src={img9} style={{ width: "100%" }} alt="Bild" />
                        </div>
                        <div className="col-xs-6 visible-xs">
                            <img src={img9mobile} style={{ width: "100%" }} alt="Bild" />
                        </div>
                        <div className="col-xs-6 visible-xs">
                            <img src={img10mobile} style={{ width: "100%" }} alt="Bild" />
                        </div>
                    </div>
                </div>
                <div id="content-themenschwerpunkte-end" className="container col-md-8 col-md-offset-2">
                    <div className="row">
                        <div className="col-md-9 rwText">
                            <h3><FormattedMessage id="themenschwerpunkte.erfolg"></FormattedMessage></h3>
                            <p>
                                <FormattedMessage id="themenschwerpunkte.erfolgText"></FormattedMessage>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// setzt die Props auf globale Status. Dadurch kann man auf den Globalen Status Language zugreifen.
export default withGlobal(
    global => ({
        language: global.language
    })
)(Themenschwerpunkte);