import React from 'react';
import "../../Style.css";


import { FormattedMessage } from 'react-intl';

class TeoMobile extends React.Component {

    render() {
        return (
            <div>
                <p><FormattedMessage id="teo.paragraph1"></FormattedMessage></p>
                <div id="ContentMobile_1" className="ProjektContentMobil" >
                    <p><FormattedMessage id="teo.paragraph2"></FormattedMessage></p>
                    <p><FormattedMessage id="teo.paragraph3"></FormattedMessage></p>
                    <p><FormattedMessage id="teo.paragraph4"></FormattedMessage></p>
                    <h3><FormattedMessage id="teo.headline1"></FormattedMessage></h3>
                    <ul className="listfla col-xs-12">
                        <li><p><FormattedMessage id="teo.secondparagraph1"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph2"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph3"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph4"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph5"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph6"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph7"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="teo.secondparagraph8"></FormattedMessage></p></li>
                    </ul>
                    <div className="mehr">
                        <p></p>
                        {/* <h3></h3> */}
                        <ul className="listfla col-md-8 ">
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default TeoMobile;