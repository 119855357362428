import React from 'react';
import "../../Style.css";


import { FormattedMessage } from 'react-intl';

class EmremsMobile extends React.Component {

    render() {
        return (
            <div>
                <p><FormattedMessage id="emrems.paragraph1"></FormattedMessage></p>
                <div id="ContentMobil_0" className="ProjektContentMobil" >
                    <p><FormattedMessage id="emrems.paragraph2"></FormattedMessage></p>
                    <p><FormattedMessage id="emrems.paragraph3"></FormattedMessage></p>
                    <p></p>
                    <h3><FormattedMessage id="emrems.headline1"></FormattedMessage></h3>
                    <ul className="listfla col-xs-12">
                        <li><p><FormattedMessage id="emrems.secondparagraph1"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="emrems.secondparagraph2"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="emrems.secondparagraph3"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="emrems.secondparagraph4"></FormattedMessage></p></li>
                    </ul>
                    <div className="mehr">
                        <p><FormattedMessage id="emrems.thirdparagraph1"></FormattedMessage></p>
                        <h3><FormattedMessage id="emrems.headline2"></FormattedMessage> </h3>
                        <ul className="listfla col-md-8">
                            <li><p><FormattedMessage id="emrems.fourthparagraph1"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph2"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph3"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph4"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph5"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph6"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph7"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph8"></FormattedMessage></p></li>
                            <li><p><FormattedMessage id="emrems.fourthparagraph9"></FormattedMessage></p></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default EmremsMobile;