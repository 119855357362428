import React from 'react';
import "../../Style.css";


import { FormattedMessage } from 'react-intl';

class PiaMobile extends React.Component {

  render() {
    return (
      <div>
        <p><FormattedMessage id="pia.paragraph1"></FormattedMessage></p>
        <div id="ContentMobile_4" className="ProjektContentMobil" >
          <p><FormattedMessage id="pia.paragraph2"></FormattedMessage> </p>
          <p><FormattedMessage id="pia.paragraph3"></FormattedMessage> </p>
          <p><FormattedMessage id="pia.paragraph4"></FormattedMessage> </p>
          <h3><FormattedMessage id="pia.headline1"></FormattedMessage> </h3>
          <ul className="listfla col-xs-12">
            <li><p><FormattedMessage id="pia.secondparagraph1"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="pia.secondparagraph2"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="pia.secondparagraph3"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="pia.secondparagraph4"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="pia.secondparagraph5"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="pia.secondparagraph6"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="pia.secondparagraph7"></FormattedMessage></p></li>
          </ul>
                <div className="mehr">
                    <p></p>
                    {/* <h3></h3> */}
                    <ul className="listfla col-md-8 ">
                    </ul>
                </div>
        </div>
      </div>
    )
  }
}

export default PiaMobile;