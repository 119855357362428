import React, { withGlobal } from 'reactn';
import "./Style.css";

//Bilder für Carousell
import boe from "../../assets/images/Projekte/boe.png";
import BoeBg from "../../assets/images/Projekte/BoeBg.jpg";
import emrems from "../../assets/images/Projekte/emrems.png";
import EmremsBg from "../../assets/images/Projekte/EmsBg.jpg";
import gefin from "../../assets/images/Projekte/gefin.png";
import GefinBg from "../../assets/images/Projekte/GefinBg.jpg";
import pia from "../../assets/images/Projekte/pia.png";
import PiaBg from "../../assets/images/Projekte/PiaBg.jpg";
import piet from "../../assets/images/Projekte/piet.png";
import PietBg from "../../assets/images/Projekte/PietBg.jpg";
import swing from "../../assets/images/Projekte/swing.png";
import teo from "../../assets/images/Projekte/teo.png";
import TeoBg from "../../assets/images/Projekte/TeoBg.jpg";
import teoanalysis from "../../assets/images/Projekte/teoanalysis.png";
import triangle from "../../assets/images/triangle.svg";

//Bilder für Kreislauf
import svgKreislauf from "../../assets/images/kreislauf.svg";
import svgProjektmobile from "../../assets/images/projektmobil.svg";

// Texte importieren
import EmremsShort from "./TextInhalte/EMREMS/EmremsShort.js";
import EmremsLong from "./TextInhalte/EMREMS/EmremsLong.js";
import EmremsMobile from "./TextInhalte/EMREMS/EmremsMobile.js";
import TeoShort from "./TextInhalte/TEO/TeoShort.js";
import TeoLong from "./TextInhalte/TEO/TeoLong.js";
import TeoMobile from "./TextInhalte/TEO/TeoMobile.js";
import TeoAnaShort from "./TextInhalte/TEOANA/TeoAnaShort.js";
import TeoAnaLong from "./TextInhalte/TEOANA/TeoAnaLong.js";
import TeoAnaMobile from "./TextInhalte/TEOANA/TeoAnaMobile.js";
import BoeShort from "./TextInhalte/BOE/BoeShort.js";
import BoeLong from "./TextInhalte/BOE/BoeLong.js";
import BoeMobile from "./TextInhalte/BOE/BoeMobile.js"
import PiaShort from "./TextInhalte/PIA/PiaShort.js";
import PiaLong from "./TextInhalte/PIA/PiaLong.js";
import PiaMobile from "./TextInhalte/PIA/PiaMobile.js";
import SwingShort from "./TextInhalte/SWING/SwingShort.js";
import SwingLong from "./TextInhalte/SWING/SwingLong.js";
import SwingMobile from "./TextInhalte/SWING/SwingMobile.js";
import GefinShort from "./TextInhalte/GEFIN/GefinShort";
import GefinLong from "./TextInhalte/GEFIN/GefinLong";
import GefinMobile from "./TextInhalte/GEFIN/GefinMobile";
import PietShort from "./TextInhalte/PIET/PietShort";
import PietLong from "./TextInhalte/PIET/PietLong";
import PietMobile from "./TextInhalte/PIET/PietMobile.js";

import { FormattedMessage } from 'react-intl';

class Projekte extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            system: "emrems",
            background: EmremsBg,
            text: EmremsShort,
            mehrInfo: false,
            title: "EMR EMS",
            mobileClicked: null
        };

        // Methoden binden
        this.changeSystem = this.changeSystem.bind(this);
        this.setMehrInfoTrue = this.setMehrInfoTrue.bind(this);
        this.setMehrInfoMobile = this.setMehrInfoMobile.bind(this);
    }

    componentDidMount() {
        window._paq.push(['setCustomUrl', "/projects"]);
        window._paq.push(['setDocumentTitle', "Projects"]);
        window._paq.push(['trackPageView']);
    }

    // Ändert den Status und triggert damit einen Rerender der Componenten die den Status beinhalten
    changeSystem(system) {
        switch (system) {
            case 'emrems':
                this.setState({
                    system: "emrems",
                    background: EmremsBg,
                    text: EmremsShort,
                    mehrInfo: false,
                    title: "EMR EMS"
                });

                break;
            case 'boe':
                this.setState({
                    system: "boe",
                    background: BoeBg,
                    text: BoeShort,
                    mehrInfo: false,
                    title: "BOE"
                });
                break;
            case 'teo':
                this.setState({
                    system: "teo",
                    background: TeoBg,
                    text: TeoShort,
                    mehrInfo: false,
                    title: "TEO"
                });
                break;
            case 'teoAna':
                this.setState({
                    system: "teoAna",
                    background: TeoBg,
                    text: TeoAnaShort,
                    mehrInfo: false,
                    title: "TEO Analysis"
                });
                break;
            case 'pia':
                this.setState({
                    system: "pia",
                    background: PiaBg,
                    text: PiaShort,
                    mehrInfo: false,
                    title: "PIA"
                });
                break;
            case 'swing':
                this.setState({
                    system: "swing",
                    background: EmremsBg,
                    text: SwingShort,
                    mehrInfo: false,
                    title: "SWING"
                });
                break;
            case 'gefin':
                this.setState({
                    system: "gefin",
                    background: GefinBg,
                    text: GefinShort,
                    mehrInfo: false,
                    title: "GEFIN"
                });
                break;
            case 'piet':
                this.setState({
                    system: "piet",
                    background: PietBg,
                    text: PietShort,
                    mehrInfo: false,
                    title: "PIET"
                });
                break;
            default:
                this.setState({
                    system: "emrems",
                    background: EmremsBg,
                    text: EmremsShort,
                    mehrInfo: false,
                    title: "EMR EMS"
                });
                break;
        }
    }

    // Wenn der Button geklickt wurde wird die erweiterte Info ausgefahren.
    setMehrInfoTrue() {
        switch (this.state.system) {
            case 'emrems':
                this.setState({
                    text: EmremsLong,
                    mehrInfo: true
                });

                break;
            case 'boe':
                this.setState({
                    text: BoeLong,
                    mehrInfo: true
                });
                break;
            case 'teo':
                this.setState({
                    text: TeoLong,
                    mehrInfo: true
                });
                break;
            case 'teoAna':
                this.setState({
                    text: TeoAnaLong,
                    mehrInfo: true
                });
                break;
            case 'pia':
                this.setState({
                    text: PiaLong,
                    mehrInfo: true
                });
                break;
            case 'swing':
                this.setState({
                    text: SwingLong,
                    mehrInfo: true
                });
                break;
            case 'gefin':
                this.setState({
                    text: GefinLong,
                    mehrInfo: true
                });
                break;
            case 'piet':
                this.setState({
                    text: PietLong,
                    mehrInfo: true
                });
                break;
            default:
                this.setState({
                    text: EmremsLong,
                    mehrInfo: true
                });

                break;
        }
    }

    // Wenn der Button geklickt wurde wird die erweiterte Info ausgefahren.
    setMehrInfoMobile(projekt) {
        switch (projekt) {
            case EmremsMobile:
                this.setState({
                    mobileClicked: EmremsMobile
                });

                break;
            case TeoMobile:
                this.setState({
                    mobileClicked: TeoMobile
                });

                break;
            case TeoAnaMobile:
                this.setState({
                    mobileClicked: TeoAnaMobile
                });

                break;
            case PiaMobile:
                this.setState({
                    mobileClicked: PiaMobile
                });

                break;
            case BoeMobile:
                this.setState({
                    mobileClicked: BoeMobile
                });

                break;
            case SwingMobile:
                this.setState({
                    mobileClicked: SwingMobile
                });

                break;
            case GefinMobile:
                this.setState({
                    mobileClicked: GefinMobile
                });

                break;
            case PietMobile:
                this.setState({
                    mobileClicked: PietMobile
                });

                break;
            default:
                this.setState({
                    mobileClicked: EmremsMobile
                });

                break;
        }
    }


    render() {
        const { language } = this.props;
        document.title = "Projects";
        return (
            <div>
                <div >
                    {/* Titlebild fpr die Mobile Version und Small Tablets */}
                    <div className="col-xs-12 visible-xs visible-sm">
                        <img src={svgProjektmobile} style={{ width: "100%" }} alt="svgMobile" />
                    </div>

                    {/* Hintergrund für die Einzelnen Projekte. Im moment nur Statisch */}
                    <div id="content-main" className="  container col-md-8 col-md-offset-2 ">
                        <div className="row">
                            {/* Der Content ist für Large Tablets und Desktop PC geeignet  */}
                            <div className="col-md-12  hidden-xs hidden-sm rwclass0">
                                <svg preserveAspectRatio="xMinYMin slice" id="SvgBackground" className="img-responsive ProjektImage ie ie9" x="0px" y="0px" viewBox="0 0 1800 650" style={{ width: "1800", x: "0", y: "0" }} >
                                    <image width="100%" height="1200" xlinkHref={this.state.background} />
                                    {(language === "de") ? <text id="textdefault" transform="matrix(1 0 0 1 300 118.8)" className="st100 st102">Wir entwickeln das passende System</text> : <text id="textdefault" transform="matrix(1 0 0 1 200 118.8)" class="st100 st102">We develop the right solution for your needs</text>}
                                    {/* Name der Titelbox */}
                                    <text transform="matrix(1 0 0 1 150 350)" className="st100 st102">{this.state.title}</text>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className=" container-fluid col-md-12 col-sm-12 rwclass hidden-xs hidden-sm">
                        <div className="col-md-12 col-sm-12 col-md-offset-1">
                            <div className="row rwclass2 ">
                                <div className="col-md-1 col-sm-1"></div>
                                <div id="box1" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("emrems")} >
                                    <img className="img-responsive" src={emrems} alt="emrems" />
                                </div>
                                <div id="box2" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("teo")}>
                                    <img className="img-responsive" src={teo} alt="teo" />
                                </div>
                                <div id="box3" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("teoAna")}>
                                    <img className="img-responsive" src={teoanalysis} alt="teoanalysis" />
                                </div>
                                <div id="box4" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("boe")}>
                                    <img className="img-responsive" src={boe} alt="boe" />
                                </div>
                                <div id="box5" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("pia")}>
                                    <img className="img-responsive" src={pia} alt="pia" />
                                </div>
                                <div id="box6" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("swing")}>
                                    <img className="img-responsive" src={swing} alt="swing" />
                                </div>
                                <div id="box7" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("gefin")}>
                                    <img className="img-responsive" src={gefin} alt="egefin" />
                                </div>
                                <div id="box8" className="ProjektBildBox col-md-1 col-sm-1 float-none" onClick={(e) => this.changeSystem("piet")}>
                                    <img className="img-responsive" src={piet} alt="piet" />
                                </div>
                                <div className="col-md-1 col-sm-1 "></div>
                            </div>

                            {/* Die Triangle */}
                            <div className="row ">
                                <div className="col-md-1 col-sm-1"></div>
                                <div id="triangle1" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "emrems") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle2" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "teo") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle3" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "teoAna") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle4" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "boe") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle5" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "pia") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle6" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "swing") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle7" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "gefin") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div id="triangle8" className="ProjektTriangle col-md-1 col-sm-1 centered" style={(this.state.system === "piet") ? { opacity: "1" } : { opacity: "0" }}>
                                    <img className="img-responsive center-block" style={{ width: "40%" }} src={triangle} alt="Bild" />
                                </div>
                                <div className="col-md-1 col-sm-1"></div>
                            </div>

                            {/* Runde ProjekteBox mit beschreibendem Text */}
                            <div className="row">
                                <div className="col-md-1 col-sm-1 "></div>
                                <div className="col-sm-8 col-xl-8 rounded box ProjektBox">
                                    <div id="box_1" className="">
                                        {/* Hier kurzer Text per default und langer Text, wenn Button geklickt wurde. */}
                                        {<this.state.text />}

                                        {/* Button hier */}
                                        <button type="button" id="buttonDesktop_1" onClick={this.setMehrInfoTrue} className="ProjektButton butter btn col-sm-4 col-lg-4 pull-right" style={(this.state.mehrInfo === true) ? { display: "none" } : {}}>
                                            <FormattedMessage id="projekte.button"></FormattedMessage>
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>




                    {/* Statisches Bild ab hier! */}
                    <div id="content-projekt" className="  container    col-md-8 col-md-offset-2 ">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-8 rwText">
                                <h4><FormattedMessage id="projekte.kreativeIdeen"></FormattedMessage></h4>
                            </div>
                        </div>

                        <div className="row abstand">
                            <div className="col-md-8 col-md-offset-2 hidden-xs ">
                                <object data={svgKreislauf} type="image/svg+xml" > svg Kreislauf </object>
                            </div>
                            <div className="col-xs-12 visible-xs ">
                                <object data={svgKreislauf} type="image/svg+xml" > svg Kreislauf </object>
                            </div>
                        </div>
                    </div>
                </div >

                {/* Bildboxen der Mobilen Versionen und Small Tabletts */}
                <div className="font-14 col-xs-12 rwclass3  visible-xs visible-sm">
                    <div className="row">
                        {/* Text vor den Boxen in der Mobil und Smalltablett Version */}
                        <div className="col-xs-8  rwText">
                            <h4><FormattedMessage id="projekte.kennenlernen"></FormattedMessage></h4>
                        </div>
                    </div>

                    <div className="row">
                        {/* EMREMS */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={EmremsBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">EMR EMS</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={emrems} alt="Bild"></img>
                                {/* Hier der Text entweder short oder mobile */}
                                {(this.state.mobileClicked === EmremsMobile) ? <EmremsMobile /> : <EmremsShort />}

                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(EmremsMobile)} style={(this.state.mobileClicked === EmremsMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* TEO */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={TeoBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">TEO</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={teo} alt="Bild"></img>
                                {/* Hier der Text entweder short oder mobile */}
                                {(this.state.mobileClicked === TeoMobile) ? <TeoMobile /> : <TeoShort />}

                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(TeoMobile)} style={(this.state.mobileClicked === TeoMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* Teo Analysis */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={TeoBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">TEO Analysis</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={teoanalysis} alt="Bild"></img>
                                {(this.state.mobileClicked === TeoAnaMobile) ? <TeoAnaMobile /> : <TeoAnaShort />}
                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(TeoAnaMobile)} style={(this.state.mobileClicked === TeoAnaMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* BOE */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={BoeBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">BOE</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={boe} alt="Bild"></img>
                                {(this.state.mobileClicked === BoeMobile) ? <BoeMobile /> : <BoeShort />}
                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(BoeMobile)} style={(this.state.mobileClicked === BoeMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* PIA */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={PiaBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">PIA</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={pia} alt="Bild"></img>
                                {(this.state.mobileClicked === PiaMobile) ? <PiaMobile /> : <PiaShort />}
                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(PiaMobile)} style={(this.state.mobileClicked === PiaMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* Swing */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={EmremsBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">SWING</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={swing} alt="Bild"></img>
                                {(this.state.mobileClicked === SwingMobile) ? <SwingMobile /> : <SwingShort />}
                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(SwingMobile)} style={(this.state.mobileClicked === SwingMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* Gefin */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={GefinBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">GEFIN</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={gefin} alt="Bild"></img>
                                {(this.state.mobileClicked === GefinMobile) ? <GefinMobile /> : <GefinShort />}
                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(GefinMobile)} style={(this.state.mobileClicked === GefinMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                        {/* Piet */}
                        <div className="col-xs-12 rounded box">
                            {/* SVG Titelbild der mobilen Version */}
                            <svg className="SvgBackgroundMobil_0  projektbild" x="0px" y="0px" viewBox="0 0 1800 1200" >
                                <image width="100%" height="1200" xlinkHref={PietBg} />
                                <text className="st100m st102m" transform="matrix(1 0 0 1 100 250)">PIET</text>
                            </svg>
                            <div className="col-xs-10 col-xs-offset-1">
                                <img className="responsive-img center-block" style={{ marginTop: "-200px" }} src={piet} alt="Bild"></img>
                                {(this.state.mobileClicked === PietMobile) ? <PietMobile /> : <PietShort />}
                                <div>
                                    <button id="ButtonMobil_0" className="ProjektButtonMobil btn butter btn col-lg-3 pull-right" onClick={(e) => this.setMehrInfoMobile(PietMobile)} style={(this.state.mobileClicked === PietMobile) ? { display: "none" } : {}}>
                                        <FormattedMessage id="projekte.button"></FormattedMessage>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        </div>

                    </div>

                </div>

            </div >
        )
    }
}

export default withGlobal(
    global => ({
        language: global.language
    })
)(Projekte);