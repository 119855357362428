import React from 'react';

import eyecatcher from "../../assets/images/technologie.jpg";

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class Kompetenzen extends React.Component {

    componentDidMount() {
        window._paq.push(['setCustomUrl', "/competences"]);
        window._paq.push(['setDocumentTitle', "Competences"]);
        window._paq.push(['trackPageView']);
    }

    render() {
        document.title = "Competences";
        return (
            <div id="content-main" className="container   col-md-8 col-md-offset-2 ">
                <div className="row">
                    <div className="col-md-12 hidden-xs">
                        <img className="eyecatcher" src={eyecatcher} style={{ width: "100%" }} alt="Bild" />
                    </div>
                    <div className="col-xs-12 visible-xs">
                        <img src={eyecatcher} style={{ width: "100%" }} alt="Bild" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-9 rwText">
                        <h3><FormattedMessage id="kompetenzen.kompetenzen"></FormattedMessage></h3>
                        <h4><FormattedMessage id="kompetenzen.anwendungsentwicklung"></FormattedMessage></h4>

                        <FormattedHTMLMessage id="kompetenzen.cSharp"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.vbNet"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.vba"></FormattedHTMLMessage>
                       
                        <FormattedHTMLMessage id="kompetenzen.java"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.spring"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.javaServerFaces"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.git"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.office"></FormattedHTMLMessage>

                        <h4><FormattedMessage id="kompetenzen.webTechnologien"></FormattedMessage></h4>

                        <FormattedHTMLMessage id="kompetenzen.html5"></FormattedHTMLMessage>
                       
                        <FormattedHTMLMessage id="kompetenzen.css"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.jquery"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.angular"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.bootstrap"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.primeFaces"></FormattedHTMLMessage>

                        <h4><FormattedMessage id="kompetenzen.buisnessIntelligence"></FormattedMessage></h4>

                        <FormattedHTMLMessage id="kompetenzen.analysisServices"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.integrationService"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.reportingServices"></FormattedHTMLMessage>
                        
                        <h4><FormattedMessage id="kompetenzen.datenbankentwicklung"></FormattedMessage></h4>

                        <FormattedHTMLMessage id="kompetenezen.msSQL"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.sql"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.mySQL"></FormattedHTMLMessage>
                        
                        <h4><FormattedMessage id="kompetenzen.projektmanagement"></FormattedMessage></h4>

                        <FormattedHTMLMessage id="kompetenzen.prince2"></FormattedHTMLMessage>
                        
                        <FormattedHTMLMessage id="kompetenzen.itil"></FormattedHTMLMessage>

                        <FormattedHTMLMessage id="kompetenzen.uml"></FormattedHTMLMessage>

                        <h4><FormattedMessage id="kompetenzen.datenschutz"></FormattedMessage></h4>

                        <FormattedHTMLMessage id="kompetenzen.zertifikate"></FormattedHTMLMessage>

                    </div>
                </div>
            </div >
        )
    }
}

export default Kompetenzen;