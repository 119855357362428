import React from 'react';
import "../../Style.css";

// Bilder Importe
import teoAna from "../../../../assets/images/Projekte/teoanalysis.png";

import { FormattedMessage } from 'react-intl';

class TeoAnaLong extends React.Component {

    render() {
        return (
            <div>
              <p><FormattedMessage id="teoAna.paragraph1"></FormattedMessage></p>
              <div id="ContentDesktop_1" className="ProjektContent" >
                <p><FormattedMessage id="teoAna.paragraph2"></FormattedMessage></p>
                <p><FormattedMessage id="teoAna.paragraph3"></FormattedMessage></p>
                <p><FormattedMessage id="teoAna.paragraph4"></FormattedMessage></p>
                <p></p>
                <h3><FormattedMessage id="teoAna.headline1"></FormattedMessage> </h3>
                <ul className="listfla col-md-8 col-sm-8">
                  <li><p><FormattedMessage id="teoAna.secondparagraph1"></FormattedMessage></p></li>
                  <li><p><FormattedMessage id="teoAna.secondparagraph2"></FormattedMessage></p></li>
                  <li><p><FormattedMessage id="teoAna.secondparagraph3"></FormattedMessage></p></li>
                  <li><p></p></li>
                </ul>
                <img src={teoAna} alt="teo" />
                <div className="col-md-12 col-sm-12">
                  <p></p>
                  {/* <h3></h3> */}
                  <ul className="listfla col-md-8 col-sm-8">
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                    <li><p></p></li>
                  </ul>
                </div>
              </div>
            </div>
          )
    }
}

export default TeoAnaLong;