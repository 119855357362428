import React from 'react';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

class Impressum extends React.Component {

    componentDidMount() {
        window._paq.push(['setCustomUrl', "/impressum"]);
        window._paq.push(['setDocumentTitle', "Impressum"]);
        window._paq.push(['trackPageView']);
    }

    render() {
        document.title = "Legal Notice";
        return (
            <div id="content-kontakt" className="container-fluid bg-themen2">
                <div className="row">
                    <div className="col-md-2 col-md-offset-2 col-xs-11 rwKontakt">
                        <div className="col-md-10 col-xs-12">
                            <h3><FormattedMessage id="impressum.impressum"></FormattedMessage></h3>
                        </div>
                    </div>
                </div >

                <div className="row">
                    <div className="col-md-5 col-md-offset-2 col-xs-12">
                        <p className="font12">
                            <b><FormattedMessage id="impressum.haftungshinweis"></FormattedMessage></b>
                            <br></br>
                            <FormattedMessage id="impressum.kontrolletext"></FormattedMessage>
                    </p>

                        <p className="font12">
                            <b><FormattedMessage id="impressum.rechenwerk"></FormattedMessage></b>
                            <br></br><FormattedMessage id="impressum.marthastraße"></FormattedMessage>
                        <br></br><FormattedMessage id="impressum.anschrift"></FormattedMessage>
                    </p>

                        <p className="font12">
                            <b>Christian Bercker</b>
                            <br></br> <FormattedMessage id="impressum.bercker.qualifikation"></FormattedMessage>
                        <br></br> Tel: +49 (0) 201-815818-76
                        <br></br> Fax: +49 (0) 201-815818-77
                        <br></br> cb@rechenwerk.com
                    </p>

                        <p className="font12">
                            <b>Julian Fieml</b>
                            <br></br> <FormattedMessage id="impressum.fieml.qualifikation"></FormattedMessage>
                        <br></br> Tel: +49 (0) 201-815818-78
                        <br></br> Fax: +49 (0) 201-815818-77
                        <br></br> jf@rechenwerk.com
                    </p>

                        <p className="font12">
                            <FormattedMessage id="impressum.gericht"></FormattedMessage>
                        <br></br> <FormattedMessage id="impressum.hrb"></FormattedMessage>
                        <br></br>  <FormattedMessage id="impressum.ust"></FormattedMessage>
                        <br></br>  <FormattedMessage id="impressum.de"></FormattedMessage>
                        <br></br> <FormattedMessage id="impressum.geschäftsführer"></FormattedMessage>
                        <br></br> Christian Bercker
                    </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2 col-md-offset-2 col-xs-11 rwKontakt">
                        <div className="col-md-9 col-xs-12">
                            <h3><FormattedMessage id="impressum.datenschutz"></FormattedMessage></h3>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-5 col-md-offset-2 col-xs-12">
                        <p className="font12">
                            <h3><b> <FormattedMessage id="impressum.webanalyse"></FormattedMessage></b></h3>

                            <h4> <b><FormattedMessage id ="impressum.daten"></FormattedMessage></b></h4>
                             <FormattedHTMLMessage id="impressum.auflistung"></FormattedHTMLMessage>
                            </p>
                    </div>
                </div>

            </div >
        )
    }
}

export default Impressum;