import React from 'react';
import "../../Style.css";

// Bilder Importe
import gefin from "../../../../assets/images/Projekte/gefin.png";

import { FormattedMessage } from 'react-intl';

class GefinLong extends React.Component {

  render() {
    return (
      <div>
        <p><FormattedMessage id="gefin.paragraph1"></FormattedMessage></p>
        <div id="ContentDesktop_1" className="ProjektContent" >
          <p><FormattedMessage id="gefin.paragraph2"></FormattedMessage></p>
          <p><FormattedMessage id="gefin.paragraph3"></FormattedMessage></p>
          <p></p>
          <h3><FormattedMessage id="gefin.headline1"></FormattedMessage> </h3>
          <ul className="listfla col-md-8 col-sm-8">
            <li><p><FormattedMessage id="gefin.secondparagraph1"></FormattedMessage> </p></li>
            <li><p><FormattedMessage id="gefin.secondparagraph2"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="gefin.secondparagraph3"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="gefin.secondparagraph4"></FormattedMessage></p></li>
            <li><p><FormattedMessage id="gefin.secondparagraph5"></FormattedMessage></p></li>
          </ul>
          <img src={gefin} alt="EMREMS" />
          <div className="col-md-12 col-sm-12">
            <p></p>
            {/* <h3></h3> */}
            <ul className="listfla col-md-8 col-sm-8">
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
              <li><p></p></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default GefinLong;