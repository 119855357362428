import React from 'react';
import "../../Style.css";


import { FormattedMessage } from 'react-intl';

class PietMobile extends React.Component {

    render() {
        return (
            <div>
                <p><FormattedMessage id="piet.paragraph1"></FormattedMessage></p>
                <div id="ContentMobil_7" className="ProjektContentMobil" >
                    <p><FormattedMessage id="piet.paragraph2"></FormattedMessage></p>
                    <p><FormattedMessage id="piet.paragraph3"></FormattedMessage></p>
                    <p><FormattedMessage id="piet.paragraph4"></FormattedMessage></p>
                    <h3><FormattedMessage id="piet.headline1"></FormattedMessage> </h3>
                    <ul className="listfla col-xs-12">
                        <li><p><FormattedMessage id="piet.secondparagraph1"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="piet.secondparagraph2"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="piet.secondparagraph3"></FormattedMessage></p></li>
                        <li><p><FormattedMessage id="piet.secondparagraph4"></FormattedMessage></p></li>
                    </ul>
                    <div className="mehr">
                        <p></p>
                        {/* <h3></h3> */}
                        <ul className="listfla col-md-8 ">
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default PietMobile;