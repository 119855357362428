import React from 'react';
import { setGlobal, withGlobal } from "reactn";

// Für das Routen
import { Link } from "react-router-dom";

import { FormattedMessage } from 'react-intl';


//import Assets
import deutschland_flag from "../../assets/images/icon_ger.png";
import england_flag from "../../assets/images/icon_eng.png";



class Footer extends React.Component {

    onclick = (event) => {
        const { language } = this.props;
        if (language === "de") {
            setGlobal({ language: "en" });
            localStorage.setItem("currentLanguage", "en");
        } else if (language === "en") {
            setGlobal({ language: "de" });
            localStorage.setItem("currentLanguage", "de");
        }

    }


    render() {
        const { language } = this.props;
        console.log(language + " Dies ist die Sprache des Props im Footer")
        return (
            <footer className="bg-4">
                <nav className="navbar navbar-default navbar-fixed-bottom">
                    <div className="row">
                        <Link className="fontfooter" exact to="/Impressum"><FormattedMessage id="footer.impressum">Impressum & Datenschutz</FormattedMessage></Link>
                        <div className="eng"><img id="flagge" src={language === "de" ? england_flag : deutschland_flag} onClick={this.onclick} className="img-responsive rounded" alt="Flagge" /></div>
                    </div>
                </nav>
            </footer>
        )
    }
}

// setzt die Props auf globale Status. Dadurch kann man auf den Globalen Status Language zugreifen.
export default withGlobal(
    global => ({
        language: global.language
    })
)(Footer);